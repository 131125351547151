import homeImg from "../Image/home-img.png";
import './Home.css'
import {motion, AnimatePresence} from 'framer-motion'

// Home Component: Displays an image and a heading

const Home = () => {
  return (
    
    <motion.div 
    className="home"
    initial={{ opacity: 0, scale:0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    
    
    transition={{
      duration: 1.4,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01]
    }}
    >
      <div className="home-img">
        <img src={homeImg} alt="Artwork" />
      </div>

      <h1>
        Unlock Your Artistic Potential:<br/> Art Lessons for Children and Adults.
      </h1>
    </motion.div>
    
  );
};

export default Home;
