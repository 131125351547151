import "./AdultForm.css";


// Kids sign form for 

const AdultForm = () => {
  return (
    <div className="AdultForm">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScE0sFlZBYe3WjwcWniaBYupuPC7TocIHOedl46wBs6VF0UMg/viewform?embedded=true" width="700" height="1400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};

export default AdultForm;
