import "./Policy.css";




const Policy = () => {
  return (
    <div className="policy">
      <p>
      <h2>Drop off & Pick up</h2>
        To ensure the safety and well-being of all students in our art classes,
        we have implemented a strict pick-up policy. No child will be released
        to anyone who is not listed on the registration form, unless we have
        written permission from the parent or legal guardian.
        <br /> <br />
        If someone new will be picking up your child, please inform us in
        advance and provide us with the necessary details, including the
        person's full name, relationship to the child, and a contact number. We
        will require a valid form of identification from the person picking up
        the child, such as a driver's license or passport, to ensure their
        identity matches the information provided. Please also inform your child
        of the new plan for pick-up time and who will be picking them up. It is
        important that children are aware of any changes to their routine and
        understand who is authorized to collect them.
        <br /> <br />
        We understand that unexpected circumstances can arise, and we will work
        with you to accommodate any changes to the pick-up arrangement. However,
        we kindly ask that you provide us with as much notice as possible and
        follow our policies to ensure the safety and well-being of your child.
        <br /> <br />
        Thank you for your cooperation and understanding in maintaining a safe
        and secure learning environment for all. If you have any questions or
        concerns about our policies, please do not hesitate to contact us.
      </p>
      
      <p>
      <h2>Health & Safety</h2>
        We want to create a safe and respectful learning environment for all
        students in our art classes. Please do not send your child to class if
        they are not feeling well, even if they do not have a fever. 
        <br /> <br />
        We cannot take any risks with the health and safety of our students and
        staff. If a child shows signs of illness during class, the guardian will
        be contacted immediately and is expected to pick up the child as quickly
        as possible.
        <br /> <br />
        Please be aware that we reserve the right to send any student home if
        they exhibit symptoms of illness or if we believe their presence could
        pose a risk to the health and safety of others. In such cases, no
        refunds or credits will be issued for missed classes.
        <br /> <br />
        Thank you for your understanding and cooperation in maintaining a safe
        and positive learning environment for all. If you have any questions or
        concerns about our policies, please do not hesitate to contact us.
      </p>
      
      {/* <p>
      <h2>Payment</h2>
        Payments are made in two installment. You will be invoiced a week before
        the lessons starts, then again six weeks after. Art supplies will be
        provided thus paying on time will secure inventory for all participance.
        We accept Cash, checks, and Zelle, and PayPal.
        <br /> <br />
        To enroll in our art classes, you are required to make a commitment to
        the full session. Half of the tuition is due at the time of enrollment,
        and the remaining half is due six weeks later. By signing this
        agreement, you acknowledge the total cost of tuition and any additional
        fees associated with your enrollment. Please note that there will be no
        reimbursements, deductions, or omissions made for absences, missed
        classes, or withdrawal for any reason.
        <br /> <br />
        In the event that a check is returned or bounced, there will be a $60
        fee owed in addition to the original amount of tuition. This fee must be
        paid before the student is allowed to attend any further classes.
        <br /> <br />
        We understand that unexpected circumstances can arise, and we will work
        with you to find a solution if you are unable to complete the full
        session. However, any requests for refunds or credits must be made in
        writing and are subject to review by our administration.
        <br /> <br />
        We appreciate your commitment to our art classes and look forward to
        working with you. If you have any questions or concerns about our
        policies, please do not hesitate to contact us.{" "}
      </p> */}
    </div>
  );
};

export default Policy;
