import "./About.css";
import stillLife from "../Image/flore.png";

// About Component: Displays a section for bio

const About = () => {
  return (
    <div className="About-container">

<div className="flore-img">
          <img src={stillLife} alt="" />
        </div>
      <div className="about-text">
      <h1>ABOUT</h1>
      <p>
        Hi, I am Flore—an artist and art educator based in Greenpoint, Brooklyn. 
       <br /> 
       <br /> 

        Drawing was always a passion of mine, but it was only upon enrolling in SUNY Brockport and graduating with a BFA that I realized my 
        love for the arts extended beyond me. So I started teaching it! 
        <br />
        I believe anything can be turned into a beautiful work of art if only you know how to properly look at it—and that’s exactly the 
        driving force behind my lesson plans, to experience the world through an artist’s eyes. 
        For this, over the course of the past five years, I have been teaching private art classes to both children and adults with the 
        goal to nurture their individual interests and help them improve their illustration skills while fostering a sense of creative 
        exploration and discovery. 
        If you are an adult looking to pick up a new hobby, or a parent hoping to raise the next Picasso, I got you covered. I tailor my 
        courses to the needs of different age groups in order to guide them through a journey of artistic growth
        Teaching art is more than color theory, composition, or art history. It’s about imparting essential life skills such as 
        observation, creativity, expression, abstract and critical thinking, and craftsmanship. And so I strive to create a positive and 
        stimulating environment where these traits can flourish beyond the canvas. 
        Based on your age and interests, during a session with me, you can expect to either learn shapes, color theory, composition, 
         drawing (contour drawing, shading, perspective, and figure drawing), painting (oil, acrylic, watercolor, and gouache), art 
         history, and much more as desired. 
        <br />
        <br /> 
        Join me on this journey and harness your creative power!
      </p>
        </div>
    </div>
  );
};

export default About;
