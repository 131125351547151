import logo from "../Image/Logo-flower.png";
import "./Nav.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Nav Component: Displays a navigation bar with logo and links

const Nav = () => {

//  UseState to capture initiate and current browser width 
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });
//  function to set browser's width 
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <div className="logo-container">
       <Link to="/"><img src={logo} alt="logo" /></Link> 
      </div>
      <nav>
        <ul className="Nav-links-container">
            <Link to="kids"><li>{windowSize.width <= 600 ? "Kids" : "Kids-Sessions"}</li></Link>
            <Link to="adults"><li>{windowSize.width <= 600 ? "Adults" : "Adults-Sessions"}</li></Link>
            <Link to="about"><li>About</li></Link>
            <a href = "mailto: caretodrawnyc@gmail.com"><li>Contact</li></a>
        </ul>
      </nav>
    </header>
  );
};

export default Nav;
