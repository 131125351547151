import "./Adults.css";
import stillLife from "../Image/still-life.png";
import { Link } from "react-router-dom";

// Adults Component: Displays a section for adult drawing sessions

const Adults = () => {
  return (
    <div className="adult-container">
      <div className="private-art-lessons">
        {" "}
        <div className="adults-hero">
          <h1> ADULT SESSIONS </h1>
          <p>
          Personalized private art lessons catering to your unique style and goals. These sessions offer more than just instruction—they are an invitation to journey into the heart of creativity. Explore different mediums, refine your craftsmanship, and unleash your unique artistic expression, all under guided mentorship. This is about more than art—it's about nurturing a passion for exploration and igniting a lifelong journey of learning. 
          </p>

          <a href="mailto: caretodrawnyc@gmail.com">
            {" "}
            <button>Contact</button>
          </a>
        </div>
        <div className="still-life-container">
          <img src={stillLife} alt="" />
        </div>
      </div>
      


      <div className="cafe-art-session">

      <h1>CAFÉ DRAWING SESSION</h1>
      <p>Join me for a free, light-hearted drawing lesson at your local cafe, where creativity meets comfort. Savor your favorite cup of coffee or tea as you etch your artistic tale on paper. I'll provide all the necessary materials - all you need to bring is your enthusiasm.</p>
      <Link to="/adultform">
          <button>Sign up</button>
        </Link>
      </div>
    </div>
  );
};

export default Adults;
