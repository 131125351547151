
import './App.css';
import Nav from './Components/Nav';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Kids from './Components/Kids';
import Adults from './Components/Adults';
import About from './Components/About';
import Policy from './Components/Policy';
import KidForm from './Components/KidForm'
import AdultForm from './Components/AdultForm'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">

      <Router>
        <Nav/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/kids" element={<Kids/>}/>
          <Route path="/adults" element={<Adults/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/policy" element={<Policy/>}/>
          <Route path="/kidform" element={<KidForm/>}/>
          <Route path="/adultform" element={<AdultForm/>}/>
        </Routes>

        <Footer/>


      </Router>
      {/* <Nav/>
      <Home/>
      <Kids/>
      <Adults/>
      <Policy/>
      <Footer/> */}
     
    </div>
  );
}

export default App;
