import "./Kids.css";
import castle from "../Image/castle.png";
import car1 from "../Image/car1.png";
import car2 from "../Image/car2.png";
import flower from "../Image/flowers.png";
import schedule from "../Image/schedule.png";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'

// Kids Component: Displays sections for kids' activities and educational background

const Kids = () => {
  return (
    <div className="kids-container">
      <section className="hero-section">
        <h1 className="hero-title">
          From Little Artists <br /> to Lifelong Learners
        </h1>
        <div className="castle-container">
          <img src={castle} alt="" />
        </div>
        <Link to="/kidform">
          <button>Sign up</button>
        </Link>
      </section>

      <section className="about-session">
        <div className="car1-container">
          <motion.img 
          src={car1} alt=""
          initial={{opacity:1, x:100}}
          animate={{
             x:["0%","400%"],
             opacity:[1,0.4,1]
          }}
         transition={{duration:2, repeat:Infinity, repeatDelay:1}}
        
        

           />
        </div>

        <div className="about-session-text">
          <h1>Kids Group Sessions </h1>
          <p>
         Think your child may just be the next Jean Michel Basquiat? Maybe they scribbled all over the walls in true Jackson Pollock 
         style? Stop here and look no further. I can’t promise they will end up at the MOMA but possibly, they will stop ruining your 
         walls by turning to paper. 
            <br />
         Care to Draw is designed to foster creativity, collaboration, and self-expression in an intimate group of six children. 
         Participants explore various art forms, from drawing to mixed media, guided by a passionate Brooklynite with extensive experience 
         in applied arts and art history. 
         <br />
         Allow me to instill confidence, intuition, and a sense of community all while nurturing that innate sense of wonder we all have 
         at a young age. From little artists to lifelong learners, my goal is to teach kids how to never stop seeing the world through a 
         creative lens. 
         <br /> 
         Sign your child up for a summer-long journey into creativity at the park. 
          </p>
        </div>

        <div className="car2-container">
          <motion.img 
          src={car2} alt="" 
          initial={{opacity:1, x:100}}
          animate={{
             x:["150%","-300%"],
             opacity:[1,0.4,1]
          }}
         transition={{duration:2, repeat:Infinity, repeatDelay:1}}
      
          />
        </div>
      </section>

      <section className="educational-sect">
        <div className="educational-sect-text">
          <h1>My Background </h1>
          <p>
            Armed with years of experience in child care and art education, my goal is to inspire young minds to explore their creative 
            potential by blending work and play, intellect and creativity. 


            <br />
            <br />
            During my time as a camp counselor, daycare teacher, and private caregiver, I've guided children on their artistic journey, 
            helping them build confidence, develop craftsmanship, and grow from little artists to lifelong art lovers. 
            <br />
            <br />
            By engaging children in meaningful conversation, I witnessed the blossoming of their focus and artistic prowess. By 
            encouraging them to experiment with color and shapes, I have seen the magic that happens when children are given the tools to 
            express themselves freely.
            <br />
            <br />
            My commitment to teaching art is fueled by the delight I see in children as they begin their creative journey. It’s a 
           privilege to contribute to their growth, happiness and channel their curiosity into art. 
          </p>
        </div>
        <div className="flower-container">
          <img src={flower} alt="" />
        </div>
      </section>

      <section className="schedule">
        <img src={schedule} alt="" />
      </section>
    </div>
  );
};

export default Kids;
