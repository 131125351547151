import "./Footer.css";
import gmail from "../Image/gmail.svg"
import insta from "../Image/insta.svg"
import { Link } from "react-router-dom";

// Footer Component: Displays a footer section with copyright and social media icons


const date = new Date();
let year = date.getFullYear();


const Footer = () => {
  return (
    <footer className="footer-container" >
        <div className="left-footer-text" >
            <p className="trademark">{`© ${year}. All rights reserved`}</p>
            <Link to="policy">Policy</Link>
         </div>


         
      

      <div className="social-media-icons">
         <a href = "mailto: caretodrawnyc@gmail.com"><img src={gmail} alt="" /></a>
         <a href="https://www.instagram.com/makelitaxyz/"  target="_blank"><img src={insta} alt="" /></a>
      </div>
    </footer>
  );
};

export default Footer;
