import "./KidForm.css";


// Kids sign form for 

const KidForm = () => {
  return (
    <div className="kidForm">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScTlfA8Sa6oP9X6NWYjgmaFvYtsbddX6GaJlDx1kZ-VxfA08A/viewform?embedded=true" width="640" height="1300" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};

export default KidForm;
